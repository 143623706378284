<template>
  <div id="commonHeader">
    <div class="container">
      <a href="/home"><img src="../../assets/img/logo.png" /></a>
      <div class="link">
        <a-link 
          v-for="(item, index) in linkList" 
          :key="index" 
          :href="item.link"
          :status="item.link == '/' + $route.name || item.link == $route.meta.url ? 'success' : 'normal'" >{{ item.title }}</a-link>
      </div>
      <UserInfoPanel @loginClick="visible = true" :loginData="loginData" @loginOut="loginOut"></UserInfoPanel>
      <WechatModal :visible="visible || ($store.state.open ? true : false)" @close="closeClick" ></WechatModal>
      <FirstLoginModal :visible="first" :loginData="loginData" @close="firstClick"></FirstLoginModal>
    </div>
  </div>
</template>

<script>
import UserInfoPanel from './UserInfoPanel.vue'
import WechatModal from './WechatModal.vue'
import FirstLoginModal from './FirstLoginModal.vue'
export default {
  components: {
    UserInfoPanel,
    WechatModal,
    FirstLoginModal
  },
  data() {
    return {
      linkList: [
        { title: '首页', link: '/home' },
        { title: '政策法规', link: '/policiesAndRegulations' },
        { title: '标准研制', link: '/standardDevelopment' },
        { title: '标准信息库', link: '/standardInformationBase' },
        { title: '双碳示范', link: '/doubleCDemonstration' },
        { title: '培训课程', link: '/trainingCourses' },
        { title: '标委会', link: '/standardCommittee' },
      ],
      visible: false,
      first: false,
      loginData: null
    }
  },
  watch: {
    '$store.state.open': {
      handler(n) {
        if(n) this.visible = true;
      }
    },
    '$store.state.userInfo': {
      handler(n, o) {
        if(n) {
          this.loginData = null;
          this.loginData = n;
          if(n.url) this.loginData.avatarUrl = n.url
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    closeClick(type) {
      if(type && type.openId) {
        this.first = type.isFirstLogin == 0 ? false : true
        this.loginData = type
      }
      this.visible = false
    },
    firstClick(type) {
      if(type) this.showPage()
      this.first = false
    },
    showPage() {
      this.$API.ForntUserDetail().then(res => {
        if(res.data.code == 200) {
          this.loginData = res.data.data;
          this.$store.commit('login_type', 1)
        }
      })
    },
    loginOut() {
      this.loginData = null;
    }
  }
  

}
</script>

<style lang='scss'>
  #commonHeader {
    position: fixed;
    top: 0;
    width: 100%;
    height: 94px;
    background: #ffffff;
    box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.08); 
    z-index: 10;

    .container {
      width: 1200px;
      padding: 0 10px;
      display: flex;
      margin: 0 auto;
      align-items: center;

      & > a {
        display: block;
        flex-shrink: 0;
        width: 464px;
        height: 94px;
        background: #05a081;
        padding: 13px 21px;

        & > img {
          width: 423px;
          height: 68px;
        }
      }

      .link {
        margin: 0 18px 0 28px;
      }

      .arco-link {
        padding: 9px 15px;
        font-size: 16px;
      }

      .arco-link-status-success {
        font-weight: 700;
      }

      .arco-link-status-normal {
        color:  #1d2129;
      }
    }
  }
  .arco-popover-popup-content {
    padding: 0px;
    background: #f2faf9;
    border: transparent;
    border-radius: 4px;
    box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.10);
  }

  .arco-popover-popup-arrow {
    background: #f2faf9;
    border: transparent;
  }

  .avator {
    
    .avator_top {
      height: 100px;
      padding: 20px;
      background: #f2faf9;
      display: flex;
      align-items: center;

      & > img {
        width: 60px;
        height: 60px;
        background: rgba(0,0,0,0.00);
        border: 3px solid #ffffff;
        border-radius: 50%;
        margin-right: 15px;
      }

      & > div {
        display: flex;
        align-items: center;

        & > div {
          width: 180px;
          margin-right: 25px;

          & > h4 {
            font-size: 16px;
            color: #303133;
            line-height: 21px;
            margin-bottom: 6px;
          }

          & > p {
            font-size: 14px;
            color: #909399;
            line-height: 20px;
          }
        }

        .arco-btn-size-medium {
          width: 100px;
        }
      }
    }

    .avator_bottom {
      padding: 10px 20px;
      background: #fff;

      .avator_bottom_inner {
        display: flex;
        align-items: center;
      }

      .un_login {
        padding: 15px 10px 18px;
        font-size: 14px;
        text-align: center;
        color: #606266;
        line-height: 26px;

        & > span {
          &:nth-child(1) {
            width: 55px;
            height: 55px;
            background: #f2faf9;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 12px;
            margin-left: 10px;

            .svg-icon {
              width: 25px;
              height: 25px;
              color: #05A081;
            }
          }
          &:nth-child(2) {
            display: inline-block;
            width: 75px;
          }
        }
      }

      .login {
        width: 85px;
        height: 75px;
        font-size: 14px;
        text-align: center;
        color: #4e5969;
        padding: 12px 0;
        line-height: 20px;
        margin: 10px 15px 0 0;

        &:hover {
          background: #05A081;
          color: #fff;

          .svg-icon { 
            color: #fff;
          }
        }

        .svg-icon {
          width: 25px;
          height: 25px;
          color: #4E5969;
          display: block;
          margin: 0 auto 6px;
        }
        &:last-child {
          margin-right: 0;
        }
      }

      .arco-divider-horizontal {
        margin: 20px 0 10px;
      }

      .exit {
        width: 96px;
        height: 32px;
        text-align: center;
        font-size: 14px;
        color: #1d2129;
        line-height: 32px;

      }
    }
  }
</style>