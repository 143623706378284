<template>
  <div id="layout">
    <CommonHeader />
    <div class="layout_container">
      <router-view v-wechat-title='$route.meta.titleName' />
    </div>
    <div v-if="hide" :style="{opacity : show ? '100%' : '0'}" class="box" @click="eventClick">
      <icon-up />
    </div>
    <CommonBottom />
  </div>
</template>

<script>
import CommonBottom from './components/CommonBottom.vue'
import CommonHeader from './components/CommonHeader.vue'
export default {
  components: {
    CommonBottom,
    CommonHeader
  },
  data() {
    return {
      show: false,
      hide: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollClick);
    let screenWidth = document.body.clientWidth;
    if(screenWidth >= 1460) {
      this.hide = true
    }else {
      this.hide = false
    }
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        if(window.screenWidth >= 1460) {
          that.hide = true
        }else {
          that.hide = false
        }
      })()
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.scrollClick)
  },
  methods: {
    eventClick() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    },
    scrollClick() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollTop > 0) {
        this.show = true;
      }else {
        this.show = false;
      }
    },

  }
}
</script>

<style lang='scss'>
  #layout {
    min-height: 100vh;

    .layout_container {
      padding-top: 134px;
      padding-bottom: 50px;
      min-height: calc(100vh - 144px);
    }

    .box {
      width: 50px;
      height: 50px;
      border-radius: 4px;
      border: 1px solid#e1e3e6;
      cursor: pointer;
      position: fixed;
      right: 80px;
      bottom: 60px;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s;
      background: #fff;

      &:hover {
        background: #f1f3f6;
      }
      
      .arco-icon {
        color: #606266;
        width: 28px;
        height: 28px;
      }
    }
  }
</style>