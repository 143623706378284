<template>
  <a-modal 
    :visible="visible" 
    :unmount-on-close="true"
    :footer="false"
    :maskClosable="false"
    modal-class="wechat_modal"
    title=""
    width="390"
    @cancel="handleCancel">
    <template #title> </template>
    <h3>微信扫一扫登录</h3>
    <div class="tip">扫码并关注<span><svg-icon icon-class="wechat"></svg-icon>广东双碳 </span>微信公众号安全快捷登录</div>
    <img v-if="wechatUrl" :src="wechatUrl" />
    <div class="tip1">扫码登录即代表您已阅读并同意 <a-button type="text" @click="goClick">《用户协议》</a-button></div>
  </a-modal>
</template>

<script>
export default {
  props: ['visible'],
  data() {
    return {
      wechatUrl: null,
      sceneId: null,
      timer: null
    }
  },
  watch: {
    visible: {
      handler(n) {
        if(n) this.showPage()
      },
      immediate: true,
    },
  },
  methods: {
    showPage() {
      this.$API.LoginQRCode().then(res => {
        if(res.data.code == 200) {
          this.wechatUrl = res.data.data.url;
          this.sceneId = res.data.data.sceneId;
          this.$store.commit('open_type', 0)
          this.waitEvent(0)
        }
      }) 
    },
    waitEvent(num) {
      if(num == 60) {
        this.$emit('close', false);
        clearTimeout(this.timer)
        return;
      }
      this.$API.WaitPollLogin({
        sceneId: this.sceneId
      }).then(res => {
        if(res.data.code == 500) {
          this.timer = setTimeout(() => {
            this.waitEvent(num + 1)
          }, 1000)
        }else {
          if(res.data.data.isFirstLogin == 0) this.$store.commit('login_type', 1)
          this.$emit('close', res.data.data)
        }
      })
    },
    handleCancel() {
      this.waitEvent(60)
      this.$emit('close', false)
    },
    goClick() {
      window.open('/userAgreement')
    }
  }
}
</script>

<style lang='scss'>
.wechat_modal {
  text-align: center;

  .arco-modal-header {
    height: 40px;
  }

  .arco-modal-body {
    padding:  0 25px 40px;
  }

  .arco-modal-close-btn {
    color: #86909C;
  }

  h3 {
    font-size: 22px;
    color: #1d2129;
    margin-bottom: 20px;
    line-height: 30px;
  }

  .tip {
    background: #f2faf9;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    color: #1d2129;
    display: flex;
    align-items: center;
    line-height: 20px;

    & > span {
      font-weight: 600;
      color: #05a081;
      margin: 0 6px;
      display: flex;
      align-items: center;

      .svg-icon {
        width: 20px;
        color: #05a081;
        height: 20px;
        margin-right: 4px;
      }
    }
  }

  img {
    margin: 30px auto;
    border: 1px solid #E5E6EB;
    width: 240px;
    height: 240px;
    display: block;

  }

  .tip1 {
    height: 20px;
    font-size: 14px;
    color: #4e5969;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .arco-btn-text {
      height: 18px;
      padding: 0;
      line-height: 18px;
      border: none;
    }
  }
}


</style>