<template>
  <a-modal 
    :visible="visible" 
    :unmount-on-close="true"
    :footer="false"
    :maskClosable="false"
    modal-class="first_modal"
    title=""
    :closable="false"
    width="390"

    @cancel="handleCancel">
    <template #title> </template>
    <h3>首次登录</h3>
    <p>请先设置姓名及所在单位全称</p>
    <a-form :model="form" layout="vertical" @submit="handleSubmit" :rules="rules" ref="formRef">
      <a-form-item field="username" label="姓名">
        <a-input size="large" v-model="form.username" placeholder="请输入姓名" :max-length="20" allow-clear show-word-limit />
      </a-form-item>
      <a-form-item :hide-asterisk="true" field="company" label="单位全称">
        <a-input size="large" v-model="form.company" placeholder="请输入您所在单位的全称" :max-length="20" allow-clear show-word-limit />
      </a-form-item>
      <a-form-item>
        <a-button html-type="submit" type="primary">提交</a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const validateName = (value, cb) => {
  let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
  if(value) {
    if(!reg.test(value)) {
      return cb('请输入格式为中文，数字，大小写字母，下划线的姓名');
    }else {
      return cb();
    }
  }else {
    return cb('请输入姓名');
  }
  
}
export default {
  props: ['visible', 'loginData'],
  data() {
    return {
      form: {
        username: '',
        company: ''
      },  
      rules: {
        username: [
          { validator: validateName, trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入您所在单位的全称', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    handleSubmit({ values, errors }) {
      if(errors) {
        return;
      }else {
        this.$API.EditForntUser({
          ...this.loginData,
          username: this.form.username,
          company: this.form.company
        }).then(res => {
          if(res.data.code == 200) {
            this.$emit('close', true)
            this.$store.commit('login_type', 1)
            this.$message.success('操作成功')
          }else {
            this.$message.error(res.data.msg || '操作失败')
          }
        })
      }
    },
    handleCancel() {
      this.$emit('close', false)
    }
  }
  
}
</script>

<style lang='scss'>
.first_modal {
  text-align: left;

  .arco-modal-header {
    height: 40px;
  }

  .arco-modal-body {
    padding:  0 30px 20px;
  }


  h3 {
    font-size: 22px;
    font-weight: 600;
    color: #303133;
    margin-bottom: 5px;
    line-height: 30px;
  }

  p {
    line-height: 20px;
    font-size: 14px;
    color: #909399;
    margin-bottom: 30px;
  }

  .arco-form-item-wrapper-col,
  .arco-input-wrapper {
    height: 50px;
    padding: 0;
  }
  .arco-form-item {
    margin-bottom: 30px;
  }

  .arco-form-item-content {
    min-height: 50px;
  }
  .arco-input-wrapper .arco-input {
    padding: 15px 12px;
  }
  .arco-input-wrapper .arco-input-suffix {
    padding-right: 12px;
  }

  .arco-btn-primary {
    width: 100%;
  }
}


</style>