<template>
  <a-popover position="br" content-class="avator">
    <a-avatar :size="42">
      <img @click="eventClick" class="pointer" alt="avatar" :src="avatarUrl" />
    </a-avatar>
    <template #content>
      <div class="avator_top">
        <img :src="avatarUrl" />
        <div v-if="!login" class="avator_right">
          <div>
            <h4>您还未登录</h4>
            <p>登录后即可体验更多功能</p>
          </div>
          <a-button type="primary" @click="$emit('loginClick')">立即登录</a-button>
        </div>
        <div v-else class="avator_right">
          <div><h4>{{ loginData.username }}</h4></div>
        </div>
      </div>
      <div class="avator_bottom">
        <div class="avator_bottom_inner" v-if="!login">
          <div class="un_login" v-for="item in typeList" :key="item.icon">
            <span><svg-icon :icon-class="item.icon"></svg-icon></span>
            <span>{{ item.title }}</span>
          </div>
        </div>
        <template v-else>
          <div class="avator_bottom_inner">
            <div @click="loginClick(item.link)" class="login pointer" v-for="item in loginList" :key="item.icon">
              <svg-icon :icon-class="item.icon"></svg-icon>
              <span>{{ item.title }}</span>
            </div>
          </div>
          <a-divider />
          <div class="clearfix" @click="loginOut"><a-link class="exit pull-right" href="javascript:void(0);">安全退出</a-link></div>
        </template>
        
      </div>
    </template>
  </a-popover>
</template>

<script>
export default {
  props: ['loginData'],
  data() {
    return {
      avatarUrl: require('@/assets/img/cchead.jpg'),
      typeList: [
        { icon: 'star_border', title: '文章收藏' },
        { icon: 'download', title: '资源下载' },
        { icon: 'apply', title: '培训报名' },
        { icon: 'submit', title: '信息投稿' },
      ],
      login: 0,
      loginList: [
        { icon: 'star_border', title: '我收藏的', link: '/personCenter/MyCollection?pager=1' },
        { icon: 'account', title: '账号设置', link: '/personCenter/UserInfoPanel' },
        { icon: 'submit', title: '我要投稿', link: '/tougao' },
      ]
    }
  },
  watch: {
    loginData: {
      handler(n, o) {
        if(n) {
          this.avatarUrl = this.loginData.avatarUrl ? this.baseUrl + this.loginData.avatarUrl : require('@/assets/img/cchead.jpg')
          this.login = 1
        }else {
          this.avatarUrl = require('@/assets/img/cchead.jpg');
          this.login = 0
        }
      },
      immediate: true
    },
  },
  methods: {
    loginOut() {
      this.$API.LoginOut().then(res => {
        this.login = 0;
        this.$store.commit('login_type', 0)
        this.$emit('loginOut')
        if(this.$route.name == 'personCenter') this.$router.push('/')
      })
    },
    eventClick() {
      if(this.$store.state.loginIn) this.$router.push('/personCenter/UserInfoPanel')
      else this.$emit('loginClick')
    },
    loginClick(link) {
      if(link.indexOf('https') == -1) this.$router.push(link)
      else window.open(link)
    }
  }
}
</script>

<style lang='scss'>
  .arco-avatar {
    background: transparent;

    .arco-avatar-image {
      border: 2px solid #ffffff;
    }
  }
  
</style>