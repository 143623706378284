<template>
  <div id="commonBottom">
    <div class="container">
      <div class="left">
        <img src="../../assets/img/bottom_logo.png" />
        <div>
          <!-- <span>技术支持:<a href="https://www.jinzhicloud.com/" target="_blank">广东金质信息技术有限公司</a></span> -->
          <!-- <span>Copyright © 2022<a href="https://www.gdis.org.cn/" target="_blank">广东省标准化研究院</a> All rights reserved.</span> -->
          <span>Copyright © 2022<a href="https://www.jinzhicloud.com/" target="_blank">广东金质信息技术有限公司</a> All rights reserved.</span>
          <span class="tip"><a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2021084390号</a></span>
        </div>
      </div> 
      <div class="right">
        <a-button @click="backClick">
          <svg-icon icon-class="message"></svg-icon>
          意见反馈
        </a-button>
        <a-button type="primary" @click="eventClick">
          <svg-icon icon-class="submit"></svg-icon>
          我要投稿
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    eventClick() {
      // if(!this.$store.state.loginIn) return this.$store.commit('open_type', 1)
      window.open('/tougao')
    },
    backClick() {
      window.open('https://tgik6tfkz3.jiandaoyun.com/f/62397b826488820008b173aa')
    },
  }
}
</script>

<style lang='scss'>
  #commonBottom {
    height: 144px;
    background: #303133;
    width: 100%;

    .container {
      width: 1200px;
      padding: 40px 10px;
      display: flex;
      height: 100%;
      margin: 0 auto;

      .left {
        width: calc(100% - 246px);
        flex-shrink: 0;

        & >img {
          margin-bottom: 10px;
          width: 420px;
          height: 32px;
        }

        & > div {
          font-size: 14px;
          color: #909399;

          & >span {
            margin-right: 10px;
          }

          a {
            color: #dcdfe6;
            margin: 0 10px;
            text-decoration: none;

            &:hover {
              color: #aaa;
            }
          }

          .tip {
            a {
              color: #909399;
              text-decoration: none;

              &:hover {
                color: #aaa;
              }
            }
          }

        }
      }

      .right {
        width: 246px;
        flex-shrink: 0;

        .arco-btn-secondary {
          margin-right: 10px;
          background: #3f4042;
          color: #fff;

          &:hover {
            color: #05A081;
            background: #fff;

            .svg-icon {
              color: #05A081;

            }
          }
          
        }

        .arco-btn {
          .svg-icon {
            width: 14px;
            height: 14px; 
            color: #fff;
            margin-right: 8px;
          }
        }
      }
    }
  }
</style>